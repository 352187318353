<template>
  <div>
    <el-form label-position="right" v-if="!field.is_global_variable">
      <title-and-description :field="field" />
      <el-row type="flex" :gutter="30">
        <el-col :span="12">
          <placeholder :field="field" />
        </el-col>
        <el-col :span="12">
          <field-filled-by :field="field" />
        </el-col>
      </el-row>
      <el-row type="flex" :gutter="30">
        <el-col :span="6">
          <el-form-item label="Select Fields">
            <br />
            <el-select
              placeholder="Select Field"
              v-model="selectedFieldKey"
              filterable
              clearable
              @change="updateSelectedField"
            >
              <el-option
                v-for="(f, index) in allFields"
                :key="index"
                :label="f.label"
                :value="f.key"
                :disabled="disableOption(f)"
              >
              </el-option>
            </el-select>
            <el-button
              size="mini"
              type="primary"
              @click="addOptionToList"
              style="margin-left: 8px"
            >
              Add
            </el-button>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="Add Separator">
            <el-input
              maxlength="50"
              min="3"
              resize="none"
              v-model="field.separator"
              placeholder="add any separator"
            ></el-input>
            <el-checkbox
              label="Remove special characters"
              v-model="field.remove_special_characters"
            ></el-checkbox>
            <el-checkbox
              label="show labels"
              v-model="field.show_labels"
            ></el-checkbox>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <is-field-required :field="field" class="field-required" />
        </el-col>
        <el-col :span="10">
        <div class="icon-dropdown-selection">
            <el-button type="text" @click="iconPickerdialogVisible = true">
              <div class="d-flex align-center menu-icon">
                <span>
                  <icons :iconName="templateIconPath"></icons>
                </span>
                <i class="el-icon-circle-plus"></i>
              </div>
            </el-button>
          </div>
          </el-col>
      </el-row>
      <el-row type="flex" :gutter="30">
        <el-col :span="12">
          <u>Drag Fields to Change order</u>
          <el-button
            type="danger"
            @click="popField()"
            class="btn-operation"
            size="mini"
            :disabled="field.selected_fields.length < 1"
            style="margin-left: 8px"
          >
            <i class="el-icon-delete"></i>
          </el-button>
          <draggable v-model="field.selected_fields">
            <th v-for="(column, index) in field.selected_fields" :key="index">
              <el-tag effect="dark">
                {{ column.label }}
              </el-tag>
            </th>
          </draggable>
        </el-col>
      </el-row>
    </el-form>
    <dialog-component
      :title="'Select An Icon'"
      :visible="iconPickerdialogVisible"
      :containerWidth="getIsMobile ? '100%' : '77%'"
      @before-close="closeSelectIcon"
      :isShowFooter="false"
      :selectedIcon="field.icon_name"
    >
      <div class="icons-component">
        <div class="iconslistScrollable">
          <icons-list @icon-data="getIconPath"></icons-list>
        </div>
      </div>
    </dialog-component>
  </div>
</template>

<script>
//import { mapGetters } from "vuex";
import draggable from "vuedraggable";
export default {
  name: "Concatenate",
  components: {
    TitleAndDescription: () => import("./TitleAndDescription"),
    IsFieldRequired: () => import("./IsFieldRequired"),
    FieldFilledBy: () => import("./FieldFilledBy"),
    Placeholder: () => import("./Placeholder"),
    draggable,
  },

  props: ["field", "fieldsData"],
  computed: {
  //  ...mapGetters("globalVariables", ["getAllGlobalVariables"]),
    allGlobalVariables() {
      return this.getAllGlobalVariables
        ? this.getAllGlobalVariables.data || []
        : [];
    },
  },
  mounted() {
    let exceptionFields = [
      "HTML",
      "HEADING",
      "STAR_RATING",
      "HORIZONTAL_LINE",
      "DATA_TABLE",
      "IMAGE",
      "VIDEO",
      "DIVISION",
      "ACTION_BUTTON",
      "SIGNATURE",
    ];

    if (this.field.data_table_field_index > -1) {
      this.allFields = this.fieldsData[
        this.field.data_table_field_index
      ].data_table_columns.filter(
        (e) =>
          e &&
          !exceptionFields.includes(e.input_type) &&
          e.key !== this.field.key
      );
    } else {
      this.allFields = this.fieldsData.filter(
        (e) =>
          e &&
          !exceptionFields.includes(e.input_type) &&
          e.key != this.field.key
      );
    }
  },
  data() {
    return {
      allFields: [],
      selectedFieldKey: "",
      selectedField: {},
      separator: "",
      iconPickerdialogVisible: false,
      getAllGlobalVariables: null
    };
  },
  methods: {
    closeSelectIcon() {
      this.iconPickerdialogVisible = false;
    },
    getIconPath(icon) {
      this.templateIconPath = icon;
      this.$set(this.field, 'icon_name', icon);
      this.iconPickerdialogVisible = false;
    },
    addOptionToList() {
      if (this.selectedFieldKey) {
        this.field.selected_fields.push(this.selectedField);
        this.selectedFieldKey = "";
        this.selectedField = {};
      } else {
        this.$message.error("Please select Field!");
      }
    },
    updateSelectedField() {
      if (this.field.data_table_field_index > -1) {
        this.selectedField = this.fieldsData[
          this.field.data_table_field_index
        ].data_table_columns.find((e) => e.key == this.selectedFieldKey);
      } else {
        this.selectedField = this.fieldsData.find(
          (e) => e.key == this.selectedFieldKey
        );
      }
    },
    disableOption(field) {
      let allSelectedFieldKeys = this.field.selected_fields.map((e) => e.key);
      if (allSelectedFieldKeys.includes(field.key)) {
        return true;
      }
      return false;
    },
    popField() {
      this.field.selected_fields.pop();
    },
  },
};
</script>

<style lang="scss"></style>
